//订单管理api


import req from "../utils/req"



//获取banner里面的数据
export function bannerStatistics_api(param) {
    return req.get("/otaHotel/order/bannerStatistics", param,true)
}
//根据职位获取当前职位下
export function getOperationsAssistantGroup_api(param) {
    return req.get("/otaHotel/hotelSon/getOperationsAssistantGroup", param)
}
//未处理订单
export function pageOrderListPre_api(param) {
    return req.get("/otaHotel/order/pageOrderListPre", param)
}
//艺龙超时警告
export function pageOrderListPreElong_api(param) {
    return req.get("/otaHotel/order/pageOrderListPreElong", param)
}
//订单/获取渠道/二次确认
export function getChannel_api(param) {
    return req.get("/otaHotel/order/getChannel", param)
}
//订单/订单类型/二次确认
export function getOrderType_api(param) {
    return req.get("/otaHotel/order/getOrderType", param)
}
//锁定/解锁订单
export function orderLock_api(param) {
    return req.get("/otaHotel/order/setLock", param)
}
//操作备注/新建事件
export function addOrderLog_api(param) {
    return req.get("/otaHotel/order/addLog", param)
}
//点击接受/我知道了
export function orderAccept_api(param) {
    return req.get("/otaHotel/order/accept", param)
}
//拒绝
export function orderRefuse_api(param) {
    return req.get("/otaHotel/order/refuse", param)
}
//同意取消
export function agreeCancel_api(param) {
    return req.get("/otaHotel/order/agreeCancel", param)
}
//拒绝取消
export function notAgreeCancel_api(param) {
    return req.get("/otaHotel/order/notAgreeCancel", param)
}
//订单详情
export function orderDetailsInfo_api(param) {
    return req.get("/otaHotel/order/orderInfo", param)
}
//转至订单
export function goToOrderType_api(param) {
    return req.get("/otaHotel/order/goToOrderType", param)
}
//客诉列表
export function pageOrderListGuest_api(param) {
    return req.get("/otaHotel/order/pageOrderListGuest", param)
}
//客诉跟进按钮
export function eventTrail_api(param) {
    return req.get("/otaHotel/order/event", param)
}
//客诉回复按钮
export function eventOver_api(param) {
    return req.get("/otaHotel/order/eventOver", param)
}
//根据角色获取组与组员|(统计方面的)统一用这个
export function getGroups_api(param) {
    return req.get("/otaHotel/horseGroup/getGroups", param)
}
//客诉订单转至
export function eventGtM_api(param) {
    return req.get("/otaHotel/order/eventGtM", param)
}
//二次确认订单列表
export function pageOrderListConfirm_api(param) {
    return req.get("/otaHotel/order/pageOrderListConfirm", param)
}
//二次确认订单列表
export function pageOrderListConfirmSelected_api(param) {
    return req.get("/otaHotel/order/pageOrderListConfirmSelected", param)
}
//手工单酒店列表
export function getHotelList_api(param) {
    return req.get("/otaHotel/manual-order/getHotelList", param)
}
//手工单酒店房间列表
export function getHotelRoomList_api(param) {
    return req.get("/otaHotel/manual-order/getHotelRoomList", param)
}
//手工单保存
export function saveManualOrder_api(param) {
    return req.postlg("/otaHotel/manual-order/saveManualOrder", param)
}
//二次确认订单确认
export function intercept_api(param) {
    return req.get("/otaHotel/order/intercept", param)
}
//推送确认号（只有在订单已确认的情况下推送）
export function confirmationNo_api(param) {
    return req.get("/otaHotel/order/ConfirmationNo", param)
}
//推送确认号（只有在订单已确认的情况下推送）
export function updateAmount_api(param) {
    return req.get("/otaHotel/order/updateAmount", param)
}

//经理收到
export function setIsConfirm_api(param) {
    return req.get("/otaHotel/order/isConfirm", param)
}

//修改订单状态
export function updateOrderStatus_api(param) {
    return req.get("/otaHotel/order/updateStatus", param)
}


//获取猩房房酒店列表
export function getXffHotelList_api(param) {
    return req.get("/otaHotel/hotelSon/getHotelListXff", param)
}
//获取猩房房酒店物理房型
export function getXffRoomTypeList_api(param) {
    return req.get("/otaHotel/hotelSon/getRoomTypeList", param)
}
//获取猩房房酒店售卖房型
export function getXffRoomList_api(param) {
    return req.get("/otaHotel/hotelSon/getRoomList", param)
}
//获取猩房房酒店售卖房型的马甲
export function getXffRoomVestList_api(param) {
    return req.get("/otaHotel/hotelSon/getRoomVestList", param)
}


//发布猩房房订单
export function saveXffManualOrder_api(param) {
    return req.postlg("/otaHotel/manual-order/saveManualOrderXff", param)
}

//猩房房全部取消订单
export function cancelAllForXff_api(param) {
    return req.get("/otaHotel/finance/report/cancel", param)
}

//猩房房部分取消（修改业绩）

export function updateProfitPriceForXff_api(param) {
    return req.postlg("/otaHotel/finance/report/updateProfitPriceXXF", param)
}


//百度渠道同意取消订单
export function agreeBaiduCancel_api(param) {
    return req.get("/otaHotel/order/agreeCancelbaidu", param)
}

//百度手动退款
export function baiduRefund_api(param) {
    return req.get("/otaHotel/order/baiduRefund", param)
}

//艺龙kpi
export function ElongkpiList_api(param) {
    return req.get("/otaHotel/elong-kpi/list", param)
}


