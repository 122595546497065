<template>
  <div>
    <div class="orderInfoHead">
      <el-row>
        <el-col :span="12"> <span>订单管理</span>>发布订单 </el-col>
        <el-col :span="12" style="color: #256def; text-align: right"
          ><span @click="goOrderPage" style="cursor: pointer"
            >返回订单管理</span
          ></el-col
        >
      </el-row>
    </div>

    <div class="hotelListBox">
      <div>
        <el-form :model="setData" label-width="110px" style="margin-top: 20px">
          <el-form-item label="酒店名称">
            <el-select
              filterable
              @change="changeHotel"
              size="mini"
              style="width: 360px"
              v-model="setData.hotelId"
            >
              <el-option
                v-for="item in hotelOption"
                :key="item.id"
                :label="item.hotelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物理房型">
            <el-select
              filterable
              @change="changeRoomType"
              size="mini"
              style="width: 360px"
              v-model="setData.roomTypeId"
            >
              <el-option
                v-for="item in roomTypeOption"
                :key="item.id"
                :label="item.roomTypeName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="售卖房型">
            <el-select
              filterable
              @change="changeRoom"
              size="mini"
              style="width: 360px"
              v-model="setData.roomId"
            >
              <el-option
                v-for="item in roomOption"
                :key="item.id"
                :label="item.roomName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="售卖马甲">
            <el-radio-group size="mini" v-model="setData.vestId">
              <el-radio
                v-for="item in roomVestList"
                :key="item.vestId"
                :label="item.vestId"
                border
               class="marginbottom20"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="第三方订单号">
            <el-input
              size="mini"
              style="width: 160px"
              v-model="setData.thirdOrderNum"
            ></el-input>
          </el-form-item>

          <el-form-item label="客人姓名">
            <el-input
              size="mini"
              style="width: 160px"
              v-model="setData.guestContact"
            ></el-input>
            <span class="formLabel">多个客人请用,隔开</span>
          </el-form-item>
          <el-form-item label="房间数">
            <el-select
              size="mini"
              style="width: 160px"
              v-model="setData.numberOfUnits"
            >
              <el-option
                v-for="item in numberOfUnitsOption"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="formLabel">间</span>
          </el-form-item>
          <el-form-item label="住宿日期">
            <el-date-picker
              v-model="setData.checkIn"
              size="mini"
              style="width: 130px"
              type="date"
              @change="changeCheckIn"
              placeholder="选择日期"
              :clearable="false"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <span class="margin10">至</span>
            <el-date-picker
              v-model="setData.checkOut"
              size="mini"
              @change="changeCheckOut"
              style="width: 130px"
              type="date"
              :clearable="false"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <span class="formLabel">
              <span>共</span>
              <span>{{ setData.totalRoomNights }}</span>
              <span> 晚 </span>
            </span>
          </el-form-item>
          <el-form-item label="每日房价">
            <div
              class="roomPriceBox"
              v-for="(item, index) in setData.dailyRatesJosnLabel"
              :key="index"
            >
              <div class="priceTitle">
                {{ item.date | initDate }}
              </div>
              <div class="priceContent">
                <span>CNY</span>
                <el-input
                  size="mini"
                  @input="getPrice"
                  style="width: 60px; height: 24px; margin-left: 10px"
                  v-model="item.price"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="每日采购价">
            <div
              class="roomPriceBox"
              v-for="(item, index) in setData.purchasePriceJosnLabel"
              :key="index"
            >
              <div class="priceTitle">
                {{ item.date | initDate }}
              </div>
              <div class="priceContent">
                <span>CNY</span>
                <el-input
                  size="mini"
                  @input="getPrice"
                  style="width: 60px; height: 24px; margin-left: 10px"
                  v-model="item.price"
                ></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="总金额">
            预付，共计<span class="fontRule">CNY</span
            ><span class="fontRule">{{ setData.totalBeforeTax }}</span>

            <div v-show="showEdit == 'true'" style="display: inline-block">
              <el-input
                size="mini"
                style="
                  width: 60px;
                  height: 24px;
                  margin-left: 10px;
                  margin-right: 10px;
                "
                v-model="setData.totalBeforeTax"
              ></el-input>
              <el-button
                size="mini"
                @click="changgeShowEdit('false')"
                type="primary"
                >保存</el-button
              >
            </div>

            <el-button @click="changgeShowEdit('true')" type="text"
              >修改</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button
          style="margin-left: 110px"
          type="primary"
          size="mini"
          @click="saveXffManualOrder"
        >
          保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  getXffHotelList_api,
  getXffRoomTypeList_api,
  getXffRoomList_api,
  getXffRoomVestList_api,
  saveXffManualOrder_api,
} from "../../apis/order";

export default {
  data() {
    return {
      setData: {
        guestContact: "",
        numberOfUnits: 1,
        checkIn: new Date(),
        checkOut: new Date(new Date().getTime() + 20 * 60 * 60 * 1000),
        totalRoomNights: 1,
        thirdOrderNum: "",

        hotelId: "",
        roomTypeId: "",
        roomId: "",
        vestId: "",

        dailyRatesJosn: '',
        dailyRatesJosnLabel: [{ date: new Date(), price: 0 }],
        purchasePriceJosn:'',
        purchasePriceJosnLabel: [{ date: new Date(), price: 0 }],
        totalBeforeTax: "",
        purchasePrice: "",
      },
      hotelOption: [],
      roomTypeOption: [],
      roomOption: [],
      roomVestList: [],
      showEdit: false,
      numberOfUnitsOption: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
    };
  },
  created() {
    if(this._isMobile()){
        this.$router.push({
        path: "/mobilereleaseOrder",
      });
    }else{
       this.getXffHotelList();
    }

    
  },
  filters: {
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    //获取猩房房酒店列表
    async getXffHotelList() {
      let { code, data } = await getXffHotelList_api(this.searchData);
      if (code == 0) {
        this.hotelOption = data;
      }
    },
    //获取猩房房酒店物理房型
    async getXffRoomTypeList() {
      let { code, data } = await getXffRoomTypeList_api({
        id: this.setData.hotelId,
      });
      if (code == 0) {
        this.roomTypeOption = data;
      }
    },
    //获取猩房房酒店售卖房型
    async getXffRoomList() {
      let { code, data } = await getXffRoomList_api({
        id: this.setData.roomTypeId,
      });
      if (code == 0) {
        this.roomOption = data;
      }
    },
    //或缺猩房房马甲
    async getXffRoomVestList() {
      let { code, data } = await getXffRoomVestList_api({
        roomId: this.setData.roomId,
      });
      if (code == 0) {
        this.roomVestList = data;
      }
    },
    //切换酒店
    changeHotel(e) {
      this.setData.roomTypeId = "";
      this.roomTypeOption = [];

      this.setData.roomId = "";
      this.roomOption = [];

      this.setData.vestId = "";
      this.roomVestList = [];

      this.getXffRoomTypeList();
    },
    //切换物理房型
    changeRoomType(e) {
      this.setData.roomId = "";
      this.roomOption = [];

      this.setData.vestId = "";
      this.roomVestList = [];

      this.getXffRoomList();
    },
    //切换售卖房型
    changeRoom(e) {
      this.setData.vestId = "";
      this.roomVestList = [];
      this.getXffRoomVestList();
    },
    //切换开始时间
    changeCheckIn(val) {
      if (this.setData.checkOut) {
        let date1 = new Date(val).getTime();
        let date2 = new Date(this.setData.checkOut).getTime();
        this.setData.totalRoomNights = Math.ceil(
          (date2 - date1) / 24 / 60 / 60 / 1000
        );
        this.setData.dailyRatesJosnLabel[0].date = val;

        this.setData.dailyRatesJosnLabel.splice(
          1,
          this.setData.dailyRatesJosnLabel.length - 1
        );
        this.setData.purchasePriceJosnLabel[0].date = val;

        this.setData.purchasePriceJosnLabel.splice(
          1,
          this.setData.purchasePriceJosnLabel.length - 1
        );

        if (this.setData.totalRoomNights > 1) {
          for (let i = 1; i < this.setData.totalRoomNights; i++) {
            this.setData.dailyRatesJosnLabel.push({
              date: new Date(
                new Date(this.setData.dailyRatesJosnLabel[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.dailyRatesJosnLabel[0].price,
            });
            this.setData.purchasePriceJosnLabel.push({
              date: new Date(
                new Date(this.setData.purchasePriceJosnLabel[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.purchasePriceJosnLabel[0].price,
            });
          }
        }
        this.getPrice();
      }
    },
    //切换结束时间
    changeCheckOut(val) {
      if (this.setData.checkIn) {
        let date2 = new Date(val).getTime();
        let date1 = new Date(this.setData.checkIn).getTime();
        this.setData.totalRoomNights = Math.ceil(
          (date2 - date1) / 24 / 60 / 60 / 1000
        );
        this.setData.dailyRatesJosnLabel[0].date = this.setData.checkIn;
        this.setData.dailyRatesJosnLabel.splice(
          1,
          this.setData.dailyRatesJosnLabel.length - 1
        );

        this.setData.purchasePriceJosnLabel[0].date = this.setData.checkIn;
        this.setData.purchasePriceJosnLabel.splice(
          1,
          this.setData.purchasePriceJosnLabel.length - 1
        );

        if (this.setData.totalRoomNights > 1) {
          for (let i = 1; i < this.setData.totalRoomNights; i++) {
            this.setData.dailyRatesJosnLabel.push({
              date: new Date(
                new Date(this.setData.dailyRatesJosnLabel[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.dailyRatesJosnLabel[0].price,
            });
            this.setData.purchasePriceJosnLabel.push({
              date: new Date(
                new Date(this.setData.purchasePriceJosnLabel[0].date).getTime() +
                  Number(i) * 24 * 60 * 60 * 1000
              ),
              price: this.setData.purchasePriceJosnLabel[0].price,
            });
          }
        }
        this.getPrice();
      }
    },

    saveXffManualOrder() {
      if (!this.setData.vestId) {
        this.$message.warning("请完善售卖房型信息");
      } else if (!this.setData.guestContact) {
        this.$message.warning("请填写客人名字");
      } 
       else if (this.setData.totalRoomNights<1) {
        this.$message.warning("请选择正确的时段");
      } 
      else {
      
        this.setData.dailyRatesJosnLabel.forEach((item) => {
          item.date = this.initDate(item.date);
        });
        this.setData.purchasePriceJosnLabel.forEach((item) => {
          item.date = this.initDate(item.date);
        });
        this.setData.checkIn = this.initDate(this.setData.checkIn);
        this.setData.checkOut = this.initDate(this.setData.checkOut);
        this.setData.dailyRatesJosn = JSON.stringify(
          this.setData.dailyRatesJosnLabel
        );
        this.setData.purchasePriceJosn = JSON.stringify(
          this.setData.purchasePriceJosnLabel
        );
        this.saveXffManualOrderFn();
      }
    },
    async saveXffManualOrderFn() {
      let { code, data } = await saveXffManualOrder_api(this.setData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
      }
    },
    //计算总价格和总采购价
    getPrice() {
      this.setData.totalBeforeTax = 0;
      this.setData.purchasePrice = 0;
      this.setData.dailyRatesJosnLabel.forEach((item) => {
        this.setData.totalBeforeTax =
          Number(this.setData.totalBeforeTax) + Number(item.price);
      });

      this.setData.purchasePriceJosnLabel.forEach((item) => {
        this.setData.purchasePrice =
          Number(this.setData.purchasePrice) + Number(item.price);
      });
      this.setData.totalBeforeTax =
        Number(this.setData.totalBeforeTax) *
        Number(this.setData.numberOfUnits);
      this.setData.purchasePrice =
        Number(this.setData.purchasePrice) * Number(this.setData.numberOfUnits);
    },
    changgeShowEdit(val) {
      this.showEdit = val;
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    //回到订单
    goOrderPage() {
      this.$router.push({
        path: "/order",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.orderInfoHead {
  margin: top 20px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.searchBox {
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.hotelListBox {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}
.hotelTitle {
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.padding20 {
  padding-left: 20px;
  padding-right: 20px;
}
.roomBox {
  margin-bottom: 20px;
}
.roomTitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}
.horseBox {
  margin-bottom: 10px;
  color: #666666;
}
.formLabel {
  margin-left: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.margin10 {
  padding-left: 10px;
  padding-right: 10px;
}
.roomPriceBox {
  width: 120px;
  height: 100px;
  border: 1px solid #e6eefa;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.priceTitle {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #e7eef4;
}
.priceContent {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
}
.fontRule {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ee4d52;
  margin-right: 10px;
}
.marginbottom20{
  margin-bottom: 20px;
}
</style>

